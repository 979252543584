import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const CardList = ({
  children,
  heading,
  asteriskHeading,
  bulletList,
  addedStyles,
}) => {
  const gridStyles = tw`grid grid-cols-4 gap-x-5 col-span-full xl:(grid-cols-12 gap-x-[20px])`

  const shadowBoxStyles = tw`font-cocon text-studio text-[26px] narc-grey-gradient leading-[1.25] relative col-span-full py-8 px-6 mb-10 mx-auto max-w-[340px] rounded-[30px] md:(col-span-2 my-0 text-[32px] leading-10) xl:(col-start-1 col-span-5 max-w-full ml-0 px-10 mr-3)`

  const textDivStyles = tw`col-span-full font-medium md:(mb-0 col-span-2 text-xl) xl:(mt-4 col-start-6 col-span-7 leading-[1.4])`

  const bList = bulletList ? tw`list-disc list-outside pl-5` : tw`hidden`

  const subStyle = tw`list-[circle] list-outside pl-8 xl:pl-14`

  return (
    <div css={[gridStyles, addedStyles && addedStyles]}>
      <div
        css={[
          shadowBoxStyles,
          tw`before:(block w-[70px] mb-7.5 h-1.5 rounded-md narc-purple-red-gradient-lighter) xl:(before:mb-5)`,
        ]}
      >
        {children}
      </div>

      <div css={textDivStyles}>
        <div tw="font-black">
          {(heading && heading) || (asteriskHeading && asteriskHeading)}
        </div>
        <ul css={bList}>
          {bulletList !== undefined &&
            bulletList.map((item, i) => {
              return (
                <li key={i} tw="mt-[17px]">
                  {item.text}
                  {item.subList ? (
                    <ul css={subStyle}>
                      {item.subList.map((item, i) => {
                        return (
                          <li key={i} tw="mt-[17px]">
                            {item.text}
                          </li>
                        )
                      })}
                    </ul>
                  ) : null}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default CardList

CardList.propTypes = {
  asteriskHeading: PropTypes.object,
  bulletList: PropTypes.array,
  addedStyles: PropTypes.object,
}

CardList.defaultProps = {
  asteriskHeading: {},
  bulletList: [],
  addedStyles: {},
}
