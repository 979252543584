import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Container from "@components/container"
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import CardList from "@components/narcolepsy/cardList"
import { Heading2, sectionMd } from "@styles/ui"
import PatientVoice from "@components/PatientVoice"
import Callout from "@components/narcolepsy/CallOut"
import PrimaryCta from "@components/PrimaryCta"

const listItemsOne = [
  {
    text: "The uncontrollable need to sleep during the day",
  },
  {
    text: "Sleep episodes can come on randomly and at unusual or unexpected times",
  },
  {
    text: "Most likely to happen in situations that don’t require active participation, such as when you are watching TV or reading a book",
  },
  {
    text: "Can also occur when it is important to be alert, such as during conversations, eating, walking, or at work",
  },
]

const ListItemsTwo = [
  {
    text: "Attacks can be fairly mild—such as a sagging jaw, drooping eyelid, head drop, slurred speech, weakness in the arm or shoulder, or slightly buckling knees",
  },
  {
    text: "In severe cases, people can fall or sink to the ground, even though they are awake and aware of their surroundings",
  },
  {
    text: "In children, cataplexy may be most noticeable by unusual movements in the face, such as eyelids drooping, eyes closing, grimacing, mouth movements, or tongue sticking out",
  },
  {
    text: "Cataplexy attacks usually last from a few seconds to a couple of minutes",
  },
]

const AboutNarcolepsyPage = ({ location, data }) => {
  return (
    <Layout location={location} activeNavIndex={0}>
      <Seo
        title="Understanding Narcolepsy Type 1 vs. Type 2 Symptoms"
        description="Understand the differences between narcolepsy type 1 and narcolepsy type 2 including the different characterizations of symptoms and a possible treatment option. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero eyebrow="About Narcolepsy" gradient="purple-red">
        Narcolepsy is a chronic, potentially disabling condition
      </StaticHero>

      <PatientVoice
        quote={
          <>
            I was happy to finally have a narcolepsy diagnosis. It felt great to
            have an answer, but at the same time, I felt overwhelmed and even a
            bit sad. Knowing that it&apos;s a lifelong disorder was difficult to
            cope with.&rdquo;
          </>
        }
        patient={"Brooke"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        cta={{
          text: "watch brooke's story",
          url: "/patient-stories/?video=1#brooke",
        }}
        footnote="Compensated for her time. Results may vary."
      />

      <section tw="pt-3 pb-[30px] md:pb-12 lg:(pt-12)" id="types-of-narcolepsy">
        <h2
          css={[
            Heading2,
            tw`mb-[30px] px-4 col-start-1 col-span-full w-full md:(mb-12 text-center) xl:(col-end-12)`,
          ]}
        >
          There are 2 types of narcolepsy: narcolepsy <nobr>type 1 </nobr>and{" "}
          <nobr>type 2</nobr>.
        </h2>
        <Container grid>
          <CardList
            heading="Everyone with narcolepsy has EDS as a symptom."
            bulletList={listItemsOne}
            addedStyles={tw`mb-[30px] md:(mb-12)`}
          >
            <p tw="text-4xl lg:text-5xl">
              Both types are characterized by the symptom of excessive daytime
              sleepiness (EDS)
            </p>
          </CardList>
          <CardList
            heading="About 70% of people with narcolepsy are believed to have cataplexy as a symptom."
            bulletList={ListItemsTwo}
          >
            <p tw="text-4xl lg:text-5xl">
              People with narcolepsy type 1 also have cataplexy, which is a
              brief loss of muscle control that can happen when you feel a
              strong emotion
            </p>
          </CardList>
        </Container>
      </section>

      <section css={[sectionMd, tw`relative bg-lilac md:text-center`]}>
        <Container>
          <h2 css={[Heading2, tw`mb-6`]}>Learning about narcolepsy</h2>

          <p tw="mb-6">
            Since there is no cure for narcolepsy, management for symptoms, such
            as EDS and cataplexy, may be needed.
          </p>

          <div tw="text-left md:(max-w-[970px] mx-auto grid grid-cols-2 gap-x-[70px])">
            <ul tw="text-sm list-disc list-outside pl-7 md:text-xl">
              <li tw="mb-6">
                EDS is the uncontrollable need to sleep during the day. Everyone
                with narcolepsy has EDS
              </li>
            </ul>
            <ul tw="text-sm list-disc list-outside pl-7 md:text-xl">
              <li tw="mb-6">
                Cataplexy is a common symptom of narcolepsy and can be described
                as when your muscles suddenly become weak or go limp when you
                feel a strong emotion. About 70% of people with narcolepsy are
                believed to have cataplexy
              </li>
            </ul>
          </div>

          <PrimaryCta url={"/narcolepsy/living-with-narcolepsy/"}>
            <>living with narcolepsy</>
          </PrimaryCta>
        </Container>
      </section>

      <Callout
        heading="XYWAV helps treat the symptoms of cataplexy and EDS"
        cta={{
          text: "what is xywav",
          url: "/narcolepsy/what-is-xywav/",
          mdSmall: true,
        }}
      >
        <>
          <p tw="font-extrabold">
            XYWAV is approved by the FDA to treat cataplexy and/or EDS in people
            ages 7 years and older with narcolepsy. In a clinical study, XYWAV,
            taken at night, has been shown to improve cataplexy and/or EDS in
            adults with narcolepsy during the day.
          </p>
        </>
      </Callout>
    </Layout>
  )
}

export default AboutNarcolepsyPage

export const query = graphql`
  query {
    colImage: file(relativePath: { eq: "about-narcolepsy/woman-phone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "about-narcolepsy/brooke-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "about-narcolepsy/brooke-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    bgImageSection: file(
      relativePath: { eq: "about-narcolepsy/bg-image-section.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "about-narcolepsy/bg-image-section-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
